<template>
  <b-card title="Organizations">
    <b-form>
      <validation-observer ref="simpleRules">
        <form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Title">
                <validation-provider #default="{ errors }" name="Title" rules="required">
                  <b-form-input v-model="title" placeholder="Title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Description">
                <validation-provider #default="{ errors }" name="Description" rules="required">
                  <b-form-input v-model="description" placeholder="This organization..." />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Icon">
                <b-form-input v-model="icon" placeholder="Icon URL" />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Owner">
                <validation-provider #default="{ errors }" name="Owner" rules="required">
                  <v-select v-model="owner" :options="users" label="name" placeholder="Select owner" class="w-100"
                    :clearable="false">
                    <template #list-header>
                      <b-button block variant="outline-primary" @click="$refs['createUser'].show()">Create User</b-button>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Members">
                <validation-provider #default="{ errors }" name="Members" rules="required">
                  <v-select v-model="members" :options="users" label="name" multiple placeholder="Select members"
                    class="w-100">
                    <template #list-header>
                      <b-button block variant="outline-primary" @click="$refs['createUser'].show()">Create User</b-button>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
                @click="validateOrganization">
                Guardar
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary"
                @click="$router.push('/organizations')">
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-form>

    <b-modal ref="createUser" hide-footer hide-header size="lg" no-close-on-backdrop>
      <UserCreate @close="closeModal" />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import OrganizationsController from '@/controllers/OrganizationsController'
import UserCreate from '@/components/atoms/users/UserCreate.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
const { log } = require('@/utils/log')

export default {
  components: {
    UserCreate,
    vSelect,
    BCard,
    BButton,
    BRow,
    BCol,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    required,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      description: "",
      owner: "",
      members: [],
      icon: "",
      id: "",
      title: "",
    };
  },

  async mounted() {
    this.id = this.$route.params.id
    if (this.$route.name === "organizations/edit") {
      const response = await this.searchOrganization(this.id)
      if (response === 404) {
        this.$router.push("/error-404")
      } else {
        this.title = response.data.title
        this.description = response.data.description
        this.owner = response.data.owner
        this.members = response.data.members
        this.icon = response.data.icon
      }
    }
  },

  async created() {
    this.$store.dispatch('getAllUsers')
  },

  computed: {
    users() {
      return this.$store.state.usersConfig.users ?
        this.$store.state.usersConfig.users
          .filter((user) => user.status === 1) :
        []
    }
  },

  methods: {
    async validateOrganization() {
      const isValid = await this.$refs.simpleRules.validate()
      if (isValid) {
        try {
          const members = this.members.map((member) => member.id)

          const organizationData = {
            title: this.title,
            description: this.description,
            fk_owner: this.owner.id,
            members: members,
            icon: this.icon,
          }

          const organizationController = new OrganizationsController()

          if (this.$route.name != "organizations/edit") {
            await organizationController.createOrganizations(organizationData)
          } else {
            await organizationController.updateOrganizations(
              this.id,
              organizationData
            )
          }
          this.$router.push("/organizations")
        } catch (err) {
          log(err)
        }
      }
    },

    async searchOrganization(id) {
      try {
        const controller = new OrganizationsController()
        return await controller.getOrganization(id)
      } catch (error) {
        log(error)
        return error.response.status
      }
    },

    closeModal() {
      this.$store.dispatch('getAllUsers')
      this.$refs['createUser'].hide()
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
