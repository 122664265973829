var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Organizations"}},[_c('b-form',[_c('validation-observer',{ref:"simpleRules"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"This organization..."},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Icon"}},[_c('b-form-input',{attrs:{"placeholder":"Icon URL"},model:{value:(_vm.icon),callback:function ($$v) {_vm.icon=$$v},expression:"icon"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Owner"}},[_c('validation-provider',{attrs:{"name":"Owner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.users,"label":"name","placeholder":"Select owner","clearable":false},scopedSlots:_vm._u([{key:"list-header",fn:function(){return [_c('b-button',{attrs:{"block":"","variant":"outline-primary"},on:{"click":function($event){return _vm.$refs['createUser'].show()}}},[_vm._v("Create User")])]},proxy:true}],null,true),model:{value:(_vm.owner),callback:function ($$v) {_vm.owner=$$v},expression:"owner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Members"}},[_c('validation-provider',{attrs:{"name":"Members","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.users,"label":"name","multiple":"","placeholder":"Select members"},scopedSlots:_vm._u([{key:"list-header",fn:function(){return [_c('b-button',{attrs:{"block":"","variant":"outline-primary"},on:{"click":function($event){return _vm.$refs['createUser'].show()}}},[_vm._v("Create User")])]},proxy:true}],null,true),model:{value:(_vm.members),callback:function ($$v) {_vm.members=$$v},expression:"members"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.validateOrganization}},[_vm._v(" Guardar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.push('/organizations')}}},[_vm._v(" Cancelar ")])],1)],1)],1)])],1),_c('b-modal',{ref:"createUser",attrs:{"hide-footer":"","hide-header":"","size":"lg","no-close-on-backdrop":""}},[_c('UserCreate',{on:{"close":_vm.closeModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }